import Head from 'next/head';
import styles from '../styles/Layout.module.css';
import Header from './Header';
import Footer from './Footer';

export default function Layouts({ title, description, keywords, canonical, children }) {

  return (
    <div>      
      <Head>
         <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonical}/>
        <link rel="icon" href="/favicon.ico" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" /> 
<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
<link rel="manifest" href="/site.webmanifest" />
<link rel="mask-icon" href="/d24-media.svg" color="#5bbad5" />

<meta name="msapplication-TileColor" content="#da532c" />
<meta name="theme-color" content="#ffffff"></meta>
 <meta name="keywords" content={keywords} />
        <meta name="theme-color" content="#999" />
      </Head>
      <Header />
      <main className={styles.container}>{children}
      <Footer /></main>
    </div>
  );
}

Layouts.defaultProps = {
  title: "D24 Media",
  description: "D24 Media the Innovator in Web 3.0 Blockchain, mobile application Producer based in Sydney, Australia",
  keywords: "D24 Media, Decentralised websites, Wordpress, Wix, React, Nextjs, Web3.0, blockchain, web producer",
  canonical: "https://d24.me",
};  