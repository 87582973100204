import Image from 'next/image'
import Link from 'next/link'
import styles from '../styles/Home.module.css'
import Layout from '../Components/Layout';
import { news } from './api/hub';
import NewsItem from "../Components/NewsItem"

export default function Home({news}) {
  return (
    <div className={styles.container}>
       <Layout>
      <main className={styles.main}>
      <h1><code className={styles.code}>D24 Digital Media</code></h1>
        <h2><code className={styles.code}>Decentralised Web 3.0 Producer. Specialise in managing website production, SEO, Digital Marketing and Blockchain Development. More about <Link href="/about"><a title="D24 Media">D24 Media</a></Link></code>
        </h2>

        <div className={styles.grid}>
          <Link href="/about" title="Dweb"><a className={styles.card}>
          <Image src="/dweb-icon.svg" width={160} height={160} alt="D24 Digital Media" title="Decentralised website"/>
            <h2>DWeb</h2>
            <p>Web3 the future of Web Technology</p>
          </a></Link>

          <Link href="/portfolio" title="Dweb"><a  className={styles.card}>
          <Image src="/develop-icon.svg" width={160} height={160} layout="fixed" alt="website design" title="website design"/>
            <h2>Develop</h2>
            <p>The Next Generation of secure website!</p>
          </a></Link>

          <Link href="/about" title="Dweb"><a className={styles.card}>
          <Image src="/deploy-icon.svg" width={160} height={160} layout="fixed" alt="website producer" title="website producer"/>
            <h2>Deploy</h2>
            <p>
              Advanced technology Cyberworld.
            </p>
          </a></Link>

          <Link href="/contact" title="Dweb"><a className={styles.card}>
          <Image src="/discover-icon.svg" width={160} height={160} layout="fixed" alt="SEO" title="SEO"/>
            <h2>Discover</h2>
            <p>100% Search Engine Friendly Website.</p>
          </a></Link>
        </div> 
</main>
<div className={styles.grid}><h2><Link href="/hub"><a className={styles.back} title="Learn Digital Media Cyber security"><code className={styles.code}>The Hub</code></a></Link></h2>
       </div>
        {news.length === 0 && <h3>No News</h3>}
        {news.map((item) => (
          <NewsItem key={item.id} news={item} />
        ))}
      </Layout>
    </div>
  )
}
 export async function getStaticProps() {
   return {
    props: { news: news.slice(0, 3) },
    revalidate: false,
   };
  }
