import Link from "next/link";
import Image from "next/image";
import styles from "../styles/NewsItem.module.css";

export default function NewsItem({ news }) {
  return (
    <div className={styles.news}>
      <div className={styles.newitems}>
      <Link href={`/hub/${news.slug}`}>
        <a title={news.name}>
        <Image
          src={news.image ? news.image : "No Image"}
          width={260}
          height={100}
          layout="responsive"
          alt={news.name}
        />
        </a></Link>
      </div>
      <div className={styles.info}>
        <h3><Link href={`/hub/${news.slug}`}><a title={news.name}>{news.name}</a></Link></h3>
      </div>
    </div>
  );
}