import Head from 'next/head';
import Image from 'next/image';
import Link from "next/link";
import styles from "../styles/Header.module.css";
import { useState } from "react";

export default function Header() {
    
  const [showMe, setShowMe] = useState(false);
  function toggle() {
    setShowMe(!showMe);
  }
  return (
    <header className={styles.header}>
    
     <div className={styles.logo}>
        <Link href="/">
          <a>
        <Image 
          itemProp="image" 
          src="/D24-Media.svg" 
          layout="fixed" 
          alt="D24 Media" 
          width={260} 
          height={260}  
          priority
          loading="eager" 
        />
        </a>
        </Link></div>

        <nav className={showMe ? styles.show : styles.hide}>
        <ul className={styles.menu}>
        <li><Link href="/about" ><a title="D24 Media">About</a></Link></li>
                <li><Link href="/portfolio"><a title="D24 Work Portfolio">Portfolio</a></Link></li>
                <li><Link href="/hub"><a title="Producer Hub">Hub</a></Link></li>
                <li><Link href="/contact" ><a title="Development Helpdesk Contact">Contact</a></Link></li>
        </ul>
      </nav>
      <button className={styles.hamb} onClick={toggle} id="d24menu" title="d24 menu">
        {showMe ? (
          <span className={styles.close}></span>
        ) : (
          <span className={styles.hambline}></span>
        )}
      </button>
    </header>
  );
}