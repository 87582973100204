import styles from "../styles/Footer.module.css";
import Link from "next/link";
import Image from 'next/image'

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <p>All Rights Reserved. D24 Media © 2024</p>
</footer>
  );
}